.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-category {
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
}

.dropdown-category:hover {
  background-color: white;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: auto !important;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: fit-content !important;
  width: fit-content !important;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
}

.li-services:hover {
  background-color: #f0f0f0;
}

.dropdown:hover{
  .dropdown-category{
    .up-icon-show{
      display: none;
    }

    .down-icon-show{
      display: inline;
    }
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}


.down-icon-show {
  display: none
}