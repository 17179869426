/* Navbar Container */
.navbar-hb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    color: white;
    position: relative;

    ul {
      Link {
        margin-bottom: 5%;
      }
    }
  }

  @media (min-width:1024px) {
    .navbar-hb{
      display: none;
    }
  }
  
  /* Hamburger Icon */
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    cursor: pointer;
  }
  
  .bar {
    height: 4px;
    width: 100%;
    background-color: black;
    transition: all 0.3s;
    border: 1px solid black;
  }
  
  /* Animation for open state */
  /* .bar.open:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
  } */
/*   
  .bar.open:nth-child(2) {
    opacity: 0;
  } */
  
  /* .bar.open:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
  } */
  
  /* Menu Styling */
  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    list-style: none;
    border: 1px solid black;
  }
  
  .menu.show {
    display: block;
    opacity: 1;
    position: absolute;
    border-radius: 8px;
    top: 43px;
    width: 241px;
    right: 14px;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 0 12px rgba(0, 0, 0, .102);
    transition: all 1s ease-in-out;
    height: 360px;
  }
  
  .menu li {
    margin: 10px 0;
  }
  
  .menu li a {
    text-decoration: none;
    color: black;
    font-size: 16px;
  }
  
  .menu li a:hover {
    color: #00bcd4;
  }
  
  .hb-items {
    margin-bottom: 5% !important;
  }